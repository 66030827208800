/*Text font*/
@font-face {
    font-family: 'GT-Haptik';

    src: url('/assets/fonts/GT-Haptik-Regular-2.eot');
    src: url('/assets/fonts/GT-Haptik-Regular-2.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GT-Haptik-Regular-2.woff') format('woff'),
        url('/assets/fonts/GT-Haptik-Regular-2.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT-Haptik-regular-oblic';
    src: url('/assets/fonts/GT-Haptik-Regular-Oblique-2.eot');
    src: url('/assets/fonts/GT-Haptik-Regular-Oblique-2.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GT-Haptik-Regular-Oblique-2.woff') format('woff'),
        url('/assets/fonts/GT-Haptik-Regular-Oblique-2.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'GT-Haptik-Bold';
    src: url('/assets/fonts/GT-Haptik-Bold-2.eot');
    src: url('/assets/fonts/GT-Haptik-Bold-2.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GT-Haptik-Bold-2.woff') format('woff'),
        url('/assets/fonts/GT-Haptik-Bold-2.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'GT-Haptik-bold-oblic';
    src: url('/assets/fonts/GT-Haptik-Bold-Oblique-2.eot');
    src: url('/assets/fonts/GT-Haptik-Bold-Oblique-2.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GT-Haptik-Bold-Oblique-2.woff') format('woff'),
        url('/assets/fonts/GT-Haptik-Bold-Oblique-2.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}


/*Icons*/
@font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon.eot?f9bj0');
    src: url('/assets/fonts/icomoon.eot?f9bj0#iefix') format('embedded-opentype'),
        url('/assets/fonts/icomoon.ttf?f9bj0') format('truetype'),
        url('/assets/fonts/icomoon.woff?f9bj0') format('woff'),
        url('/assets/fonts/icomoon.svg?f9bj0#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon', fantasy !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-x:before {
    content: "\e900";
}

.icon-world:before {
    content: "\e901";
}

.icon-warning:before {
    content: "\e902";
}

.icon-trash:before {
    content: "\e903";
}

.icon-search:before {
    content: "\e904";
}

.icon-plus:before {
    content: "\e905";
}

.icon-pencil:before {
    content: "\e906";
}

.icon-list:before {
    content: "\e907";
}

.icon-incomplete:before {
    content: "\e908";
}

.icon-grid:before {
    content: "\e909";
}

.icon-filter:before {
    content: "\e90a";
}

.icon-clock:before {
    content: "\e90b";
}

.icon-chevron-down:before {
    content: "\e90c";
}

.icon-checkmark:before {
    content: "\e90d";
}

.icon-asterisk:before {
    content: "\e90e";
}

.icon-arrow-right:before {
    content: "\e90f";
}

.icon-arrow-down:before {
    content: "\e910";
}

.icon-new-tab:before {
    content: "\ea7e";
}

.icon-checkmark-circled:before {
    content: "\2713";
}

.icon-cross:before {
    content: "\ea0f";
}

.icon-circled-check:before {
    content: "\2714";
}