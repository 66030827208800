.u-link-primary {
    color: $primary-color;
}

@include opacity;

.u-text {
    &--disabled {
        color: var(--neutral-darkest) !important;

    }
}