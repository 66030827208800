/*Variables : based on Styleguide */

/*Colors*/
$base-color: #1c1c1b;
$primary-color: #1702f8;
$primary-color-dark: #1403cd;
$primary-color-darkest: #0f00b6;
$error-color: rgb(226, 0, 108);
$error-color-ligth: rgba(254, 84, 77, 0.25);
$error-color-background :#feeded;
$success-color: rgb(0, 184, 153);
$success-color-ligth : rgba(0, 184, 153, 0.25);
$success-color-background : #d8f4ef;
$warning-color: #faad14;
$warning-color-background : #fdeed1;
$neutral-darkest: #797775;
$neutral-darker: rgb(187, 182, 179);
$neutral-dark: #ecebe7;
$neutral-light: #faf9f7;
$neutral: #ecebe7;
$lightest-color: #ffffff;
$dark-grey: #E5E3DF;
$placeholder-color: rgba(28, 28, 27, 0.3);
$background-light-gray: #ECEBE7;
$background-lighter-gray: #f4f3ef;
$rideau-salmon: #FEB793;
$sunset-orange: #fe544d !default;
/*Input*/
$input-default-height: 45px;
$input-small-height: 1.3;

/*Font*/
$primary-font-family: 'GT-Haptik', sans-serif;
$bold-font-family: 'GT-Haptik-Bold', sans-serif;

:root {
    /*Colors*/
    --base-color: #1c1c1b;
    --primary-color: #1702f8;
    --primary-color-dark: #1403cd;
    --primary-color-darkest: #0f00b6;
    --error-color: rgb(226, 0, 108);
    --error-color-ligth: rgba(254, 84, 77, 0.25);
    --error-color-background: #feeded;
    --success-color: rgb(0, 184, 153);
    --success-color-ligth: rgba(0, 184, 153, 0.25);
    --success-color-background: #d8f4ef;
    --warning-color: #faad14;
    --warning-color-background: #fdeed1;
    --neutral-darkest: #797775;
    --neutral-darker: rgb(187, 182, 179);
    --neutral-dark: #ecebe7;
    --neutral-light: #faf9f7;
    --neutral: #ecebe7;
    --lightest-color: #ffffff;
    --dark-grey: #E5E3DF;
    --grey-700: #bbb6b3;
    --darker-grey: #F5F3EF;
    --placeholder-color: rgba(28, 28, 27, 0.3);
    --background-light-gray: rgb(237, 236, 232);
    --background-lighter-gray: #f4f3ef;
    --rideau-salmon: #FEB793;
    --rideau-caribbean-green: #00d1ae;
    --sunset-orange: #fe544d;
    --green-700: #00b899;
    /*Input*/
    --input-default-height: 45px;
    --input-small-height: 1.3;
    --border-radius-default: 4px;
    /*Font*/
    --primary-font-family: 'GT-Haptik', sans-serif;
    --bold-font-family: 'GT-Haptik-Bold', sans-serif;
    --font-size-default: 14px !important;
    --line-height-default: 1.4;
}