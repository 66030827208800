button[appButton],
a.app-button {
    background: var(--lightest-color);
    border: 1px solid var(--dark-grey);
    border-radius: 4px;
    padding: 6px 20px;
    color: $base-color !important;
    font-family: $bold-font-family !important;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:has(i:not(.anticon)) {
        padding: 6px 20px 6px 10px;

        &.icon-only {
            padding: 10px 14px !important;
        }
    }

    svg {
        margin-right: 5px;
        width: 20px;
    }

    i:not(.anticon) {
        font-size: 18px;
        margin-right: 2px;

        &+* {
            margin-left: 8px;
        }
    }

    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:active:not([disabled]) {
        background: darken(#f4f3ef, 2%);
        color: $base-color;
    }

    &:active:not([disabled]),
    &:focus:not([disabled]) {
        box-shadow: 1px 1px 1px darken(#E5E3DF, 5%) inset;
    }

    &[disabled] {
        color: lighten($base-color, 50%) !important;
        cursor: not-allowed !important;
        pointer-events: none !important;

        &,
        &:hover {
            border-color: lighten($base-color, 50%);
        }
    }

    &.blue {
        background-color: #1702f8;
        border-color: #1702f8;
        color: white !important;

        &:hover:not([disabled]) {
            background: darken(#1702f8, 5%);
            color: white !important;
        }

        &:active:not([disabled]),
        &:focus:not([disabled]) {
            box-shadow: 0px 0px 2px 0px #000 inset;
            background: darken(#1702f8, 8%);
            color: white !important;
        }

        &[disabled] {
            color: lighten($base-color, 50%) !important;
            background-color: lighten(#1702f8, 20%);
            border-color: lighten(#1702f8, 20%);
            cursor: not-allowed;
        }
    }

    &.green {
        background-color: #00D1AE;
        border-color: #00D1AE;
        color: white;

        &:hover:not([disabled]) {
            background: darken(#00D1AE, 5%);
        }

        &:active:not([disabled]) {
            box-shadow: 0px 0px 2px 0px #000 inset;
        }
    }

    &.provisory {
        background: $warning-color;
        border: 2px solid rgba(254, 183, 147, 0.4);
        color: white;

        &:hover:not([disabled]) {
            border: 2px solid $warning-color;
            background: rgba(254, 183, 147, 0.2);
        }

        &:active:not([disabled]) {
            box-shadow: 0px 0px 2px 0px #000 inset;
        }
    }

    &.red {
        background-color: #FE544D;
        border-color: #FE544D;
        color: white;

        &:hover:not([disabled]) {
            background: darken(#FE544D, 5%);
        }

        &:active:not([disabled]) {
            box-shadow: 0px 0px 2px 0px #000 inset;
        }
    }

    &.loading {
        @keyframes spinner {
            to {
                transform: rotate(360deg);
            }
        }

        position: relative;
        padding-right: 45px;

        &:after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            right: 15px;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            border-radius: 50%;
            border: 2px solid #ccc;
            border-top-color: #000;
            animation: spinner .6s linear infinite;
        }
    }

    &.link {
        position: relative;

        svg {
            fill: #1702F8;
            position: relative;
            top: 4px;
            margin: 0;
        }

        color: #1702F8;
        display: inline-flex;
        background: transparent;
        border: none;
        font-family: "GT-Haptik",
        sans-serif !important;
        padding: 0;

        &:hover:not([disabled]) {
            background: transparent;
            text-decoration: underline;
        }

        &:active:not([disabled]) {
            box-shadow: transparent;
        }

        &.loading {
            &:after {
                top: 50%;
                left: 50%;
            }
        }
    }

    &.btn-square {
        border: 2px solid #E5E3DF;

        i:not(.anticon) {
            font-size: 22px;
            margin-right: 0;
        }
    }
}



// Action buttons


.c-action-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: white;
    border: 2px solid #e5e3df;
    row-gap: 10px;
    margin-top: 5px;
    border-radius: 6px;

    &::before {
        content: '';
        width: 20px;
        height: 20px;
        background: white;
        border-top: 2px solid #e5e3df;
        border-top-left-radius: 4px;
        position: absolute;
        top: -5px;
        transform: rotate(45deg);
        left: 50%;
        margin-left: -10px;
        border-left: 2px solid #e5e3df;
    }
}

.c-action__title {
    font-size: smaller !important;
}

.c-action-button {
    display: flex;

    &--red {
        --button-color: #fff;
        background-color: #FE544D !important;
        border-color: #FE544D !important;
        color: var(--button-color) !important;

        &:hover {
            --button-color: var(--base-color);
            background-color: #FE544D !important;
        }
    }

    &--yellow {
        --color: #fff;
        background: #faad14 !important;
        border-color: rgba(254, 183, 147, 0.4) !important;
        color: var(--color) !important;

        &:hover {
            --color: var(--base-color);
            background: #ffa500 !important;
        }
    }

    &--white {
        --color: var(--base-color);
        --background-color: var(--lightest-color);
        background: var(--background-color) !important;
        color: var(--color) !important;

        &:hover {
            --color: var(--base-color);
            --background-color: var(--neutral-dark);
        }
    }

    &--green {
        --color: var(--lightest-color);
        --background-color: var(--green-700);
        background: var(--background-color) !important;
        border-color: rgba(#00b899, 0.4) !important;
        color: var(--color) !important;

        &:hover {
            --color: var(--base-color);
        }
    }

    &--disabled {
        background-color: var(--neutral-dark) !important;
        border-color: var(--neutral-dark) !important;
        color: var(--neutral-darkest) !important;
        pointer-events: none;
    }
}