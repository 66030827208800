.tag {
    --background-color: var(--lightest-color, #fff);
    --foreground-color: var(--base-color, #1c1c1b);
    color: var(--foreground-color);
    margin: 3px 3px 3px 0;
    padding: 2px 5px;
    border-radius: 2px;
    display: inline-block;
    background-color: var(--background-color);
    font-weight: 500;

    &--black {
        --background-color: var(--base-color, #1c1c1b);
        --foreground-color: var(--lightest-color, #fff);
    }
}